import React from 'react'
import { ChakraProvider, extendTheme, Box, Flex, Heading, Text, Button, Icon, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FaExclamationTriangle, FaHome } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

// Define types for our theme
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

export interface ErrorScreenProps {
  error?: Error | undefined
  returnURL?: string
  hideHeader?: boolean | undefined
}

const ErrorScreen: React.FC<ErrorScreenProps> = (props) => {
  const navigate = useNavigate()
  return (
    <ChakraProvider theme={theme}>
      {(props.hideHeader == undefined || props.hideHeader == false) && (
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
        <Flex
          mx='auto'
          px={4}
          align='center'
          justify='space-between'
        >
          <Flex align='center'>
            <Image src='/logo.png' alt='Soloflow Logo' height='60px'/>
          </Flex>
        </Flex>
      </Box>
      )}
      
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgGradient="linear(to-b, #FFE0B2, #FFF5E6)"
      >
        <Flex
          direction="column"
          align="center"
          textAlign="center"
          p={8}
          bg="rgba(0,0,0,0.6)"
          borderRadius="lg"
          maxWidth="600px"
          color="white"
        >
          <motion.div
            initial={{ rotate: -10 }}
            animate={{ rotate: 10 }}
            transition={{ repeat: Infinity, duration: 1, repeatType: "reverse" }}
          >
            <Icon as={FaExclamationTriangle} w={20} h={20} mb={4} color="brand.500" />
          </motion.div>
          <Heading
            as={motion.h1}
            size="2xl"
            mb={4}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Oops! Error Occurred
          </Heading>
          <Text
            fontSize="xl"
            mb={4}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            as={motion.p}
          >
            {props.error?.message || "We're experiencing some technical difficulties."}
          </Text>
          <Text
            fontSize="md"
            mb={8}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            as={motion.p}
          >
            Please try again later.
          </Text>
          <Button
            as={motion.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            size="lg"
            colorScheme="orange"
            bg="white"
            color="brand.700"
            _hover={{
              bg: 'brand.100',
              color: 'brand.800'
            }}
            onClick={() => navigate(props.returnURL || '/')}
            leftIcon={<FaHome />}
          >
            Back to the Main Stage
          </Button>
        </Flex>
      </Box>
    </ChakraProvider>
  )
}

export default ErrorScreen