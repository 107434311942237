import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Button,
  Text,
  Spacer,
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface EmailModalProps {
  isOpen: boolean
  onClose: () => void
  setHasProvidedEmailOrSignedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const EmailModal: React.FC<EmailModalProps> = ({
  isOpen,
  onClose,
  setHasProvidedEmailOrSignedIn
}) => {
  const navigate = useNavigate()
  const { projectID, trackID } = useParams<{
    projectID: string
    trackID: string
  }>()

  const handleSignIn = () => {
    const currentPath = `/projects/${projectID}/${trackID}`
    navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}`)
  }

  const handleViewAsGuest = () => {
    setHasProvidedEmailOrSignedIn(true)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Welcome</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Text>Please sign in or continue as a guest to proceed:</Text>
            <Button onClick={handleViewAsGuest} colorScheme='gray' width='100%'>
              View as Guest
            </Button>
            <Button onClick={handleSignIn} colorScheme='blue' width='100%'>
              Sign In
            </Button>
            <Spacer/>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EmailModal
