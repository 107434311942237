import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
  VStack,
  Text
} from '@chakra-ui/react'

import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { Project } from '../../_helpers/types'
import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import axios from 'axios'
import { BASE_URL } from '../../config'

interface RenameProjectProps {
  project: Project
  isOpen: boolean
  onClose: () => void
}

const RenameProject = ({
  project,
  isOpen,
  onClose,
}: RenameProjectProps) => {
  const toast = useToast()
  const [newProjectName, setNewProjectName] = React.useState<string>('')
  const [user, loading, error] = useAuthState(auth)

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const handleRenameProject = async () => {
    try {
      if (user && project) {
        const projectID = project.id

        await axios.put(`${BASE_URL}/projects/${projectID}`, {
          name: newProjectName,
        })
      }

      onClose()

      // TODO: Make this a refetch instead of a reload
      window.location.reload()
      // fetchVersions()
    } catch (error) {
      toast({
        title: 'Error renaming project',
        description: 'Error renaming the project. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderColor='orange.200' borderWidth={1}>
        <ModalHeader color='orange.800'>Rename Project</ModalHeader>
        <ModalCloseButton color='orange.800' />
        <ModalBody>
          <VStack spacing={4} align='stretch'>
            <Text color='orange.800'>Enter a new name for the project:</Text>
            <Input
              type='text'
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              size='md'
              variant='filled'
              bg='beige'
              _hover={{ bg: 'orange.100' }}
              _focus={{ bg: 'orange.100', borderColor: 'orange.300' }}
              color='orange.800'
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg='orange.400'
            color='white'
            _hover={{ bg: 'orange.500' }}
            onClick={handleRenameProject}
            isDisabled={
              newProjectName === '' ||
              newProjectName.replace(/\s/g, '') === '' ||
              newProjectName === project.name
            }
            size='md'
          >
            Rename
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RenameProject
