import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Button,
  AspectRatio,
  Heading,
  Container,
  HStack,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';

interface Feature {
  title: string;
  description: string;
  sidenote?: string;
  hook: string;
  videoUrl: string;
}

const features: Feature[] = [
  {
    title: "Time-Stamped Comments",
    description: "Drop precise comments directly on the audio file.",
    hook: "Skip the confusion, tackle issues separately",
    videoUrl: "/timestamped_commenting_feature.mp4",
  },
  {
    title: "In App & Email Notifications",
    description: "Receive immediate notifications when users reply.",
    hook: "Make sure you never miss an update again.",
    videoUrl: "/inapp_notifications_feature.mp4",
  },
  {
    title: "Project Management",
    description: "Projects, tracks, & collaborators - all in one place.",
    hook: "No more digging through emails and folders",
    videoUrl: "/project_management_feature.mp4",
  },
  {
    title: "Version Control System",
    description: "Easily compare versions and track your progress.",
    hook: "No more 'final_v3_REALLY_FINAL.mp3'",
    videoUrl: "/version_control_feature.mp4",
  },
];

const FeatureTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box py={20} bg="orange.50">
      <Container maxW="container.xl">
        <Heading as="h2" size="2xl" textAlign="center" mb={16}>
          Get More Done. Faster.
        </Heading>

        {isMobile ? (
          <VStack spacing={8} align="stretch">

            {/* Tabs Section */}
            <VStack align="stretch" spacing={4}>
              {features.map((feature, index) => {
                return (
                <><Button
                    key={index}
                    onClick={() => setActiveTab(index)}
                    variant={activeTab === index ? 'solid' : 'ghost'}
                    colorScheme="orange"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height="auto"
                    py={4}
                    px={4}
                    borderRadius="md"
                    _active={{ bg: 'orange.200' }}
                    _hover={activeTab !== index ? { bg: 'orange.100' } : {}}
                    textAlign="left"
                    boxShadow="md"
                    whiteSpace="normal" // Allows text to wrap
                  >
                    <VStack align="start" spacing={1} width="100%">
                      <Text fontWeight="bold" fontSize="xl">
                        {feature.title}{' '}
                        {feature.sidenote && (
                          <Text as="span" color="orange.50">
                            ({feature.sidenote})
                          </Text>
                        )}
                      </Text>
                      <Text fontSize="md" color="gray.600" fontWeight="medium">
                        {feature.description}
                      </Text>
                      <Text fontSize="md" color="gray.600" fontWeight="medium">
                        {feature.hook}
                      </Text>
                    </VStack>
                  </Button>
                  {activeTab === index && (
                    <Box
                    bg="orange.800"
                    borderRadius="lg"
                    overflow="hidden"
                    boxShadow="md"
                    width="100%"
                  >
                      <AspectRatio ratio={16 / 9}>
                        <video
                          key={features[activeTab].videoUrl}
                          src={features[activeTab].videoUrl}
                          controls
                          width="100%"
                          height="100%"
                          style={{ objectFit: 'cover' }}
                          loop
                          autoPlay
                          muted
                          playsInline />
                      </AspectRatio>
                    </Box>
                  )}
                  </>
              )})}
            </VStack>
          </VStack>
        ) : (
          // Desktop Layout
          <Flex borderRadius="lg" height="550px">
            <VStack
              align="stretch"
              width="35%"
              spacing={4}
              pr={4}
              height="100%"
            >
              {features.map((feature, index) => (
                <Button
                  key={index}
                  onClick={() => setActiveTab(index)}
                  variant={activeTab === index ? 'solid' : 'ghost'}
                  colorScheme="orange"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  height="auto"
                  py={4}
                  px={4}
                  borderRadius="md"
                  _active={{ bg: 'orange.200' }}
                  _hover={activeTab !== index ? { bg: 'orange.100' } : {}}
                  textAlign="left"
                  flex={1}
                  boxShadow="md"
                >
                  <VStack align="start" spacing={1} width="100%">
                    <Text fontWeight="bold" fontSize="xl" width="100%">
                      {feature.title}{' '}
                      {feature.sidenote && (
                        <Text as="span" color="orange.50">
                          ({feature.sidenote})
                        </Text>
                      )}
                    </Text>
                    <Text
                      fontSize="md"
                      color={activeTab === index ? 'white' : 'gray.600'}
                      width="100%"
                      whiteSpace="normal"
                      fontWeight="medium"
                    >
                      {feature.description}
                    </Text>
                    <Text
                      fontSize="md"
                      color={activeTab === index ? 'white' : 'gray.600'}
                      width="100%"
                      whiteSpace="normal"
                      fontWeight="medium"
                    >
                      {feature.hook}
                    </Text>
                  </VStack>
                </Button>
              ))}
            </VStack>
            <Box
              width="65%"
              bg="orange.800"
              borderRadius="lg"
              overflow="hidden"
              boxShadow="md"
            >
              <AspectRatio ratio={16 / 9} height="100%">
                <video
                  key={features[activeTab].videoUrl}
                  src={features[activeTab].videoUrl}
                  controls
                  width="100%"
                  height="100%"
                  style={{ objectFit: 'cover' }}
                  loop
                  autoPlay
                  muted
                  playsInline
                />
              </AspectRatio>
            </Box>
          </Flex>
        )}
      </Container>

      <Box p={6} mt={16}>
        <Container maxW="container.xl">
          <HStack
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            justifyContent="space-evenly"
            spacing={isMobile ? 6 : 8}
          >
            <Box
              bg="white"
              p={6}
              borderRadius="md"
              boxShadow="md"
              width={isMobile ? '100%' : '45%'}
            >
              <VStack spacing={4}>
                <Text fontSize="xl" fontWeight="medium" color="black">
                  Faster Communication Than
                </Text>
                <HStack spacing={6}>
                  <Image
                    src="/whatsapp.png"
                    alt="WhatsApp"
                    boxSize="40px"
                    objectFit="contain"
                  />
                  <Image
                    src="/imessage.png"
                    alt="iMessage"
                    boxSize="40px"
                    objectFit="contain"
                  />
                  <Image
                    src="/gmail.png"
                    alt="Gmail"
                    boxSize="40px"
                    objectFit="contain"
                  />
                </HStack>
              </VStack>
            </Box>

            <Box
              bg="white"
              p={6}
              borderRadius="md"
              boxShadow="md"
              width={isMobile ? '100%' : '45%'}
            >
              <VStack spacing={4}>
                <Text fontSize="xl" fontWeight="medium" color="black">
                  Easier File Sharing Than
                </Text>
                <HStack spacing={6}>
                  <Image
                    src="/googledrive.png"
                    alt="Google Drive"
                    boxSize="40px"
                    objectFit="contain"
                  />
                  <Image
                    src="/soundcloud.png"
                    alt="SoundCloud"
                    boxSize="40px"
                    objectFit="contain"
                  />
                  <Image
                    src="/wetransfer.png"
                    alt="WeTransfer"
                    boxSize="40px"
                    objectFit="contain"
                  />
                </HStack>
              </VStack>
            </Box>
          </HStack>
        </Container>
      </Box>
    </Box>
  );
};

export default FeatureTabs;
