import React, { useState, createContext, useContext, ReactNode } from 'react'
import axios from 'axios';
import { BASE_URL } from '../../config';
import { Notification } from '../../_helpers/types';
import type { ToastId, UseToastOptions } from '@chakra-ui/react';

const NotificationContext = createContext<NotificationContextType|null>(null);

interface NotificationContextType {
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  unreadNotifications: { [threadId: string]: number };
  setUnreadNotifications: React.Dispatch<React.SetStateAction<{ [threadId: string]: number }>>
  unreadCount: number;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>
  countAndSetUnreadNotifications: () => Promise<void>
  markThreadAsRead: (threadId: string) => void;
  fetchUnreadNotifications: (userId: string) => Promise<void>;
  markRelatedNotificationsAsRead: (threadID: string, userID: string, toast: (options?: UseToastOptions) => ToastId) => Promise<void>
}

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unreadNotifications, setUnreadNotifications] = useState<{ [threadId: string]: number }>({});
  const [unreadCount, setUnreadCount] = useState(0)

  const markThreadAsRead = (threadId: string) => {
    setUnreadNotifications((prev) => ({
      ...prev,
      [threadId]: 0,
    }));
    setNotifications((prev: Notification[]) =>
        prev.map((n) =>
          n.threadID === threadId ? { ...n, read: true } : n
        )
      );
  };

  const markRelatedNotificationsAsRead = async (threadID: string, userID: string, toast: (options?: UseToastOptions) => ToastId) => {    
    if (unreadNotifications[threadID] && unreadNotifications[threadID] > 0) {
      try {
        const markReadObject = {
          thread_id: threadID,
          recipient_id: userID,
        }

        const response = await axios.put(`${BASE_URL}/notifications/read-thread`, markReadObject);
        markThreadAsRead(threadID);  // Mark thread as read in the context
        setUnreadNotifications((prev) => ({
          ...prev,
          [threadID]: 0,  // Clear local state for this thread's unread count
        }));

        if (response.data) {
          console.log(response.data.message);  // Log the success message
        }
      } catch (error) {
        console.error('Error marking notifications as read:', error);
    
        toast({
          title: 'Error',
          description: 'Unable to mark notifications as read. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    
  };

  // Fetch unread notifications for a user
  const fetchUnreadNotifications = async (userId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/notifications/${userId}/unread`);
      const notificationsByThread = response.data.unread_counts as { [threadId: string]: number }

      setUnreadNotifications(response.data.unread_counts);  // Assume the API returns { [threadId]: count }

      for (const thread of Object.keys(notificationsByThread)) {
        setUnreadCount(prev => prev + notificationsByThread[thread])
      }
    } catch (error) {
      console.error('Error fetching unread notifications:', error);
    }
  };

  const countAndSetUnreadNotifications = async () => {
    let out = 0
    for (const thread of Object.keys(unreadNotifications)) {
      out += unreadNotifications[thread]
    }
    setUnreadCount(out)
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        unreadNotifications,
        setUnreadNotifications,
        unreadCount,
        setUnreadCount,
        countAndSetUnreadNotifications,
        markThreadAsRead,
        fetchUnreadNotifications,
        markRelatedNotificationsAsRead
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  
  // If the context is null, it means it's being used outside of a provider
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  
  return context;  // Now TypeScript knows the context is non-null
};

