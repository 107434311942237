import React, { useState, useEffect } from 'react'
import {
  Button,
  HStack,
  VStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Input,
  useToast,
  Heading,
  Box,
  IconButton,
  Icon,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar
} from '@chakra-ui/react'
import { CloseIcon, LinkIcon } from '@chakra-ui/icons'
import emailjs from '@emailjs/browser'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import { auth } from '../firebase'
import { updatePermissions, getUsersForProject } from '../api/permission/permissions'

interface ShareClientProps {
  projectName: string
  projectID: string
  isOwner: boolean
}

const ShareClient: React.FC<ShareClientProps> = (props) => {
  const toast = useToast()
  const [user] = useAuthState(auth)
  const [invitees, setInvitees] = useState<string[]>([])
  const [emailInput, setEmailInput] = useState('')

  const [collaborators, setCollaborators] = useState<string[]>([]) // List of emails

  useEffect(() => {
    const fetchCollaborators = async () => {
      const usersList = await getUsersForProject(props.projectID)
      setCollaborators(usersList)
    }

    fetchCollaborators()
  }, [props.projectID])

  const handleEmailInput = (input: string) => {
    const emails = input.split(',').map(email => email.trim()); // Split by comma and trim whitespace

    // Check if the last item in the array is a partial email (not yet complete)
    const lastEmail = emails[emails.length - 1];
    
    // Extract valid emails to add to invitees
    const validEmails = emails
      .slice(0, -1)
      .filter(email => email.includes('@') && !invitees.includes(email));

    if (validEmails.length) {
      setInvitees([...invitees, ...validEmails]); // Add new valid emails to invitees list
    } else if (emails.length > 1) {
      toast({
        title: 'Invalid email',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 3000,
        isClosable: true
      })
    }
    
    // Update the input field to show any partially entered email
    setEmailInput(lastEmail);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    handleEmailInput(e.target.value);
  };

  const handleKeyPressForEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const currentEmail = emailInput.trim();
      if (currentEmail.includes('@') && !invitees.includes(currentEmail)) {
        setInvitees([...invitees, currentEmail]); 
      } else {
        toast({
          title: 'Invalid email',
          description: 'Please enter a valid email address.',
          status: 'warning',
          duration: 3000,
          isClosable: true
        })
      }
      setEmailInput(''); // Clear input after pressing Enter
    }
  };
  const handleRemoveInvitee = (email: string) => {
    setInvitees(invitees.filter((invitee) => invitee !== email))
    // remove them from project.userIDs?
  }

  const handleCopyLink = () => {
    const linkToCopy = window.location.href
    navigator.clipboard.writeText(linkToCopy)
    toast({
      title: 'Share Link Copied',
      description: 'The share link has been copied to your clipboard!',
      status: 'success',
      duration: 3000,
      isClosable: true
    })

    // Track Share Link Copied event
    amplitude.track('Share Link Copied', {
      projectID: props.projectID,
      userID: user?.uid
    })
  }

  const handleSendInvite = () => {
    // Filter invitees that are not already in the collaborators list
    const newInvitees = invitees.filter(
      (invitee) => !collaborators.includes(invitee)
    );

    if (newInvitees.length > 0) {
      // Send emails to the filtered list
      sendEmail(newInvitees);
        
      // Add unique invitees to the collaborators list
      setCollaborators((prevCollaborators) => [
        ...prevCollaborators,
        ...newInvitees.map((invitee) => invitee)
      ]);
    } else {
      toast({
        title: 'Warning',
        description: 'This person is already a collaborator.',
        status: 'warning',
        duration: 5000,
        isClosable: true
      })
    }
  
    // Clear the invitees list after processing
    setInvitees([]);
  };
  

  const sendEmail = (inviteesList: string[]) => {
    try {
      // TODO: Convert to env variables for frontend
      const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
      const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string
      const userPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string      

      inviteesList.forEach((invitee) => {

        // Update permissions in the database for each invitee
        updatePermissions(props.projectID, invitee)

        // TODO: retry sending email if it fails
        emailjs.send(
          serviceId,
          templateId,
          {
            projectName: props.projectName,
            freelancerName: user?.displayName,
            clientLink: `${window.location.href}`,
            clientEmail: invitee
          },
          {
            publicKey: userPublicKey
          }
        )

        amplitude.track('Project Shared', {
          projectID: props.projectID,
          userID: user?.uid
        })
      })

      toast({
        title: 'Email Sent Successfully!',
        description: 'Your project has been shared!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error sending your email. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleClose = () => {
    setInvitees([])
  }

  return (
    <HStack>
      <Popover onClose={handleClose}>
        <PopoverTrigger>
          <Button
            colorScheme='orange'
            fontSize='lg'
            variant='outline'
            _hover={{ color: 'white', bg: 'orange.500' }}
            _active={{ color: 'white', bg: 'orange.500' }}
            size='md'
          >
            Share
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width='400px'>
            <PopoverCloseButton />
            <PopoverBody>
              <Tabs>
                <TabList>
                  <Tab>Invite</Tab>
                  <Tab>Share Link</Tab>
                  <Tab>Collaborators</Tab>
                </TabList>
                <TabPanels>
                <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Invite users to collaborate!
                    </Heading>
                    <Text marginBottom='5px' color='gray.600'>
                      Collaborator Emails (comma-separated)
                    </Text>
                    <Input
                      value={emailInput}
                      onChange={handleEmailChange}
                      onKeyDown={handleKeyPressForEmail}
                      placeholder='Enter emails separated by commas'
                      mb={2}
                    />
                    <VStack mt={4} align='stretch' spacing={2}>
                      {invitees.map((invitee) => (
                        <Box
                          key={invitee}
                          p={2}
                          borderWidth={1}
                          borderRadius='md'
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Text>{invitee}</Text>
                          <IconButton
                            aria-label='Remove invitee'
                            icon={<Icon as={CloseIcon}/>}
                            onClick={() => handleRemoveInvitee(invitee)}
                            variant='ghost'
                            size='sm'
                          />
                        </Box>
                      ))}
                    </VStack>
                    <Button
                      onClick={handleSendInvite}
                      colorScheme='orange'
                      fontSize='md'
                      variant='ghost'
                      _hover={{ color: 'white', bg: 'orange.400' }}
                      _active={{ color: 'white', bg: 'orange.400' }}
                      size='sm'
                      isDisabled={invitees.length === 0}
                      my={2}
                    >
                      Send Invites
                    </Button>
                  </TabPanel>
                  <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Share Link (View Only)
                    </Heading>
                    <Tooltip label='Copy Share Link' aria-label='Copy Share Link'>
                      <Button
                        colorScheme='orange'
                        variant='outline'
                        _hover={{ color: 'white', bg: 'orange.400' }}
                        size='sm'
                        onClick={handleCopyLink}
                        leftIcon={<LinkIcon />}
                        width='100%'
                      >
                        Copy Link
                      </Button>
                    </Tooltip>
                  </TabPanel>
                  <TabPanel>
                    {collaborators.map((collaborator, index) => (
                      <HStack key={index} spacing={2} p={1}>
                        <Avatar name={collaborator} size='sm' />
                        <Box>
                          <Text fontWeight='bold' fontSize='sm'>
                            {collaborator}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack>
  )
}

export default ShareClient
