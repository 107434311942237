import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  extendTheme,
  Flex,
  Badge,
  Container,
  Heading,
  Image,
  Grid,
  GridItem,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  useToast,
  useDisclosure
} from '@chakra-ui/react';
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
import { FaHourglassStart, FaComment, FaUserCircle, FaFileAudio, FaBars } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Footer from './common/Footer';

// Define types for our theme
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

const PricingPage = () => {
  const navigate = useNavigate();
  const boxBgColor = useColorModeValue('white', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message
    };
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
    const userPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string      

    const templateID = 'template_c00snqd';

    try {
      await emailjs.send(serviceID, templateID, templateParams, userPublicKey);
      toast({
        title: 'Quote request sent successfully',
        description: "We'll get back to you soon!",
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      setFormData({ name: '', email: '', message: '' });
      onClose();
    } catch (error) {
      toast({
        title: 'Error sending quote request',
        description: "Please try again later.",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const plans = [
    {
      name: "Waveform Starter",
      price: { monthly: "Free" },
      features: [
        {
          main: "Shared Projects",
          sub: [
            { text: "Share w/ Unlimited Users", icon: FaUserCircle, info: null },
            { 
              text: "Limited Comments Every 12 Hours", 
              icon: FaHourglassStart,
              info: "Private Projects Have Unlimited Comments"
            },
            { text: "Unlimited Tracks", icon: FaFileAudio, info: null },
            { text: "Unlimited Versions", icon: FaBars, info: null },
          ]
        },
        { text: "Up to 50GB Data", info: null},
      ],
      audience: "For Aspiring Audio Creators",
      cta: "Start Free"
    },
    {
      name: "Audio Pro",
      price: { monthly: 7.99 },
      features: [
        {
          main: "Shared Projects",
          sub: [
            { text: "Share w/ Unlimited Users", icon: FaUserCircle, info: null },
            { text: "Unlimited Comments", icon: FaComment, info: null },
            { text: "Unlimited Tracks", icon: FaFileAudio, info: null },
            { text: "Unlimited Versions", icon: FaBars, info: null },
          ]
        },
        { text: "Up to 50GB Data", info: null},
        { text: "Minimal Cost for Add-on Storage", info: "Minimal Costs for Add-on Storage" },
      ],
      audience: "For Seasoned Professionals",
      cta: "Get Pro"
    },
    {
      name: "Enterprise",
      price: { monthly: "Custom" },
      features: [
        "Custom Features",
        "Custom Requirements",
        "Custom Integrations",
      ],
      audience: "For Studios and Institutions",
      cta: "Request a Quote"
    }
  ];

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" bg={'orange.100'}>
        {/* Navbar */}
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex mx='auto' px={4} align='center' justify='space-between'>
            <Flex align='center'>
              <Button onClick={() => navigate('/')} variant='link'>
                  <Image src='/logo.png' w='150px' />
              </Button>
            </Flex>
            <HStack>
              <Button variant='ghost' onClick={() => navigate('/login')}>Log in</Button>
              <Button colorScheme='brand' onClick={() => navigate('/signup')}>Sign up</Button>
            </HStack>
          </Flex>
        </Box>

        <Container maxW="container.xl" py={20}>
          <VStack spacing={12} align="center">
            <VStack spacing={4}>
              <Heading as="h1" size="2xl" textAlign="center">Choose Your Plan</Heading>
            </VStack>

            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={8}>
              {plans.map((plan, index) => (
                <GridItem key={plan.name}>
                  <Box bg={boxBgColor} p={8} borderRadius="lg" boxShadow="md" height="100%" position="relative" display="flex" flexDirection="column">
                    {index === 1 && (
                      <Badge bg="orange.500" color='white' position="absolute" top="-3" right="-3" px={3} py={1} borderRadius="full">
                        Most Popular
                      </Badge>
                    )}
                    <VStack align="start" spacing={2} height="100%">
                      <Text fontSize="3xl" fontWeight="bold">{plan.name}</Text>
                      <HStack>
                        <Text fontSize="xl" fontWeight="bold">
                          {typeof plan.price.monthly === 'number' ? `$${plan.price.monthly} /mo` : plan.price.monthly}
                        </Text>
                        {index === 0 && (
                          <Text fontSize="sm" color="gray.500" alignSelf={'flex-end'} mb = {1}>
                            *No credit card required
                          </Text>
                        )}
                      </HStack>
                      
                      <Box borderColor="orange.500" borderWidth={2} color="orange.500" p={2} borderRadius="md" w="full" textAlign="center" fontWeight={'medium'} fontSize={'lg'} mt={2}>
                        {plan.audience}
                      </Box>

                      <List spacing={4} flex={1} mt={4}>
                        {plan.features.map((feature, i) => (
                          <ListItem key={i} fontWeight={"medium"} color={"black"} fontSize={"md"}>
                            {typeof feature === 'string' ? (
                              <>
                                <ListIcon as={CheckIcon} color="green.500" />
                                {feature}
                              </>
                            ) : feature.sub ? (
                              <VStack align="start" spacing={2}>
                                <HStack>
                                  <ListIcon as={CheckIcon} color="green.500" />
                                  <Text fontWeight="medium">{feature.main}</Text>
                                </HStack>
                                <List spacing={2} pl={8}>
                                  {feature.sub.map((subFeature, j) => (
                                    <ListItem key={j} display="flex" alignItems="center" fontWeight={"normal"} >
                                      <ListIcon as={subFeature.icon} color={subFeature.icon === FaHourglassStart ? "red.500" : "orange.300"} />
                                      <Text fontSize="sm">{subFeature.text}</Text>
                                      {subFeature.info && (
                                        <Tooltip label={subFeature.info} placement="top">
                                          <InfoIcon ml={1} color="orange.500" boxSize={3} />
                                        </Tooltip>
                                      )}
                                    </ListItem>
                                  ))}
                                </List>
                              </VStack>
                            ) : (
                              <HStack>
                                <ListIcon as={CheckIcon} color="green.500" />
                                <Text>{feature.text}</Text>
                                {feature.info && (
                                  <Tooltip label={feature.info} placement= "top">
                                    <InfoIcon color="orange.500" boxSize={3} />
                                  </Tooltip>
                                )}
                              </HStack>
                            )}
                          </ListItem>
                        ))}
                      </List>
                      {index === 1 && (
                        <Text
                          as={motion.p}
                          fontSize="sm"
                          textAlign="left"
                          mt={8}
                          color="red"
                          fontWeight="bold"
                          initial={{ scale: 1 }}
                          animate={{ scale: [1, 1.2, 1]}}
                        >
                          Hurry - Receive 3 weeks of Audio Pro for FREE!
                        </Text>
                      )}
                      {index === 2 && (
                        <Text fontSize="sm" textAlign="center" mt={8}>
                          * We&apos;ll get back within 24 hours
                        </Text>
                      )}
                      <Button
                        as={motion.button}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        colorScheme="orange"
                        size="lg"
                        w="full"
                        onClick={index === 2 ? onOpen : undefined}
                      >
                        {plan.cta}
                      </Button>
                    </VStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </VStack>
        </Container>
      </Box>
      <Footer/>

      {/* Quote Request Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request a Quote</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <Input
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
                <Input
                  placeholder="Your Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
                <Textarea
                  placeholder="Tell Us About Your Usage (2-3 Sentences)"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  required
                />
                <Button type="submit" colorScheme="brand" w="full">
                  Send Quote Request
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default PricingPage;