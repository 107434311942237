import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';

interface AnimatedSectionProps {
  children: ReactNode;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children }) => {
  return (
    <Box as={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ ease: "easeOut" }}
    >
      {children}
    </Box>
  );
};

export default AnimatedSection;