import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Text,
  Input,
  Button,
  Box,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'
import { Track } from '../../_helpers/types'
import axios from 'axios'
import { BASE_URL } from '../../config'

interface TrackDetailsProps {
  isOpen: boolean
  onClose: () => void
  track: Track | null
  fetchTracksData: () => void
}

const TrackDetails = ({ isOpen, onClose, track, fetchTracksData }: TrackDetailsProps) => {
  const toast = useToast()

  const [trackName, setTrackName] = useState(track?.name || '')
  // const [trackArtist, setTrackArtist] = useState(track?.artist || '')

  const bgColor = useColorModeValue('beige', 'gray.900')
  const textColor = useColorModeValue('black', 'white')
  const inputBgColor = useColorModeValue('orange.100', 'gray.800')
  const borderColor = useColorModeValue('orange.200', 'gray.700')
  const selectedTabColor = useColorModeValue('orange.500', 'orange.200')

  const onCloseTrackDetails = () => {
    setTrackName('')
    onClose()
    fetchTracksData()
  }

  const handleSaveChanges = async () => {
    try {
      await axios.put(`${BASE_URL}/tracks/${track?.id}`, {
        name: trackName
      })
      toast({
        title: 'Track updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (error) {
      console.error('Error updating track:', error)
    }

    onCloseTrackDetails()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      <ModalOverlay />
      <ModalContent bg={bgColor} color={textColor}>
        <ModalHeader borderBottom='1px' borderColor={borderColor}>
          Track Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Tabs isFitted variant='enclosed'>
            <TabList>
              <Tab
                _selected={{
                  color: selectedTabColor,
                  borderBottom: '2px solid',
                  borderColor: selectedTabColor
                }}
              >
                Details
              </Tab>
              <Tab
                _selected={{
                  color: selectedTabColor,
                  borderBottom: '2px solid',
                  borderColor: selectedTabColor
                }}
              >
                Versions
              </Tab>
              <Tab
                _selected={{
                  color: selectedTabColor,
                  borderBottom: '2px solid',
                  borderColor: selectedTabColor
                }}
              >
                Attachments
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4} align='stretch'>
                  {/* <Box>
                    <Text mb={2}>Track Artwork</Text>
                    <Image
                      src='/api/placeholder/100/100'
                      alt='Track Artwork'
                      boxSize='100px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  </Box> */}
                  <Box>
                    <Text mb={2}>Track Name</Text>
                    <Input
                      value={trackName}
                      onChange={(e) => setTrackName(e.target.value)}
                      bg={inputBgColor}
                      borderColor={borderColor}
                    />
                  </Box>
                  {/* <Box>
                    <Text mb={2}>Track Artist</Text>
                    <Input
                      value={trackArtist}
                      onChange={(e) => setTrackArtist(e.target.value)}
                      bg={inputBgColor}
                      borderColor={borderColor}
                    />
                  </Box> */}
                  <Button colorScheme='orange' onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                </VStack>
              </TabPanel>
              <TabPanel>
                <Text>Versions content goes here</Text>
              </TabPanel>
              <TabPanel>
                <Text>Attachments content goes here</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {/* <Flex align='center' p={4} borderTop='1px' borderColor={borderColor}>
            <FaInfoCircle />
            <Text ml={2} fontSize='sm' color='gray.500'>
              Created 6 days ago by Roshan D
            </Text>
          </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TrackDetails
