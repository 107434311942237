import React from 'react'
import { Box, Button, Center, Flex, Icon, Text } from '@chakra-ui/react'
import { FaMobileAlt } from 'react-icons/fa'

export interface MobileAccessErrorScreenProps {
  home?: string
}

const MobileAccessErrorScreen: React.FC<MobileAccessErrorScreenProps> = ({
  home = '/'
}) => {
  return (
    <Box p='5' borderWidth='1px'>
      <Center>
        <Flex align='baseline' mt={5}>
          <Icon as={FaMobileAlt} w={375} h={175} />
        </Flex>
      </Center>
      <Box>
        <Center>
          <Text
            mt={2}
            fontSize='4xl'
            fontWeight='semibold'
            lineHeight='short'
            noOfLines={2}
            textAlign='center'
          >
            Mobile Access Restricted
          </Text>
        </Center>
        <Center>
          <Text
            mt={2}
            fontSize='xl'
            fontWeight='semibold'
            lineHeight='short'
            textAlign='center'
          >
            Please use a desktop computer to access this page.
          </Text>
        </Center>
      </Box>
      <Center>
        <Text mt={2} textAlign='center'>
          This page is not available on mobile devices. We apologize for the inconvenience. 
        </Text>
      </Center>
      <Center>
        <Flex mt='35px' align='center'>
          <Button colorScheme='blue' onClick={() => (window.location.href = home)}>
            Go Home
          </Button>
        </Flex>
      </Center>
    </Box>
  )
}

export default MobileAccessErrorScreen
