import React from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import { Routes, Route } from 'react-router-dom'
import ReactGA from 'react-ga4'
import * as amplitude from '@amplitude/analytics-browser'

import LandingPage from './_components/LandingPage'
import Login from './_components/auth/Login'
import Signup from './_components/auth/Signup'
// import VerifyEmail from './_components/auth/VerifyEmail'
// import ForgotPassword from './_components/auth/ForgotPassword'
import ProjectsHome from './_components/project/ProjectsHome'
import ProjectTracks from './_components/project/ProjectTracks'
import RevisionPage from './_components/RevisionPage'
import CustomerSegmentation from './_components/common/CustomerSegmentation'
import Sidebar from './_components/common/Sidebar'
import PricingPage from './_components/PricingPage'
import NotFoundPage from './_components/NotFoundPage'
// import FeedbackButton from './_components/common/FeedbackButton'
import { NotificationProvider } from './_components/common/NotificationContext'

import { io } from "socket.io-client";
import { BASE_URL } from './config'

// const BACKEND_URL = "http://localhost:8000/"; // Ensure this is correct
export const socket = io(BASE_URL, {
  transports: ["websocket", "polling"],  // Prefer WebSocket first
  upgrade: true,  // Allow transport upgrade from polling to WebSocket
});
socket.on("connect", () => {
  console.log("Connected to WebSocket server");
});

socket.on("connect_error", (err) => {
  console.error("Connection error:", err);
});

socket.on("disconnect", () => {
  console.log("Disconnected from WebSocket server");
});

socket.emit("hello", "world", () => {
  console.log("Server replied:");
});

export const App = () => {
  ReactGA.initialize('G-BZYDXRF29Y')
  amplitude.init('fb57ff0a9d944dd5baa691695427a1b9')

  const [showSidebar, setShowSidebar] = React.useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // force close the sidebar when showSidebar is false
  React.useEffect(() => {
    if (!showSidebar) {
      onClose()
    }
  }, [showSidebar, onClose])

  return (
    <NotificationProvider>
      <Box bgGradient='linear(to-br, orange.100, white)'>
        {showSidebar && (
          <Sidebar
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={() => {
              onClose()
            }}
            showSidebar={showSidebar}
          />
        )}
        <Box ml={showSidebar && isOpen ? '300px' : '0'} transition='margin-left 0.3s'>
          <Routes>
            <Route path='/' element={<LandingPage setShowSidebar={setShowSidebar} />} />
            <Route path='/login' element={<Login setShowSidebar={setShowSidebar} />} />
            <Route path='/signup' element={<Signup setShowSidebar={setShowSidebar} />} />
            <Route path='/pricing' element={<PricingPage/>} />

            {/* <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/forgot-password' element={<ForgotPassword />} /> */}
            <Route
              path='/survey'
              element={<CustomerSegmentation setShowSidebar={setShowSidebar} />}
            />
            <Route
              path='/projects'
              element={<ProjectsHome setShowSidebar={setShowSidebar} />}
            />
            <Route
              path='/projects/:projectID'
              element={<ProjectTracks setShowSidebar={setShowSidebar} />}
            />
            <Route
              path='/projects/:projectID/:trackID'
              element={<RevisionPage setShowSidebar={setShowSidebar} />}
            />
            {/* Catch-all route for 404 Not Found */}
            <Route path="*" element={ <NotFoundPage />} />
          </Routes>
          {/* <Box textAlign="right" bg = 'beige'>
            <FeedbackButton/>
          </Box> */}
        </Box>
      </Box>
    </NotificationProvider>
  )
}

export default App
