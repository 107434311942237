import axios from 'axios';
import { BASE_URL } from '../../config';

// Fetch users for a specific project
async function getUsersForProject(projectID: string): Promise<string[]> {
  try {
    const response = await axios.get(`${BASE_URL}/projects/${projectID}/users`);
    return response.data.userEmails; // Assuming the backend response contains a list of emails
  } catch (error) {
    console.error('Error fetching users for project:', error);
    return [];
  }
}

// Add a user to the project's permissions
async function updatePermissions(projectID: string, email: string): Promise<void> {
  try {
    await axios.post(`${BASE_URL}/projects/${projectID}/permissions`, { email });
    console.log(`Successfully updated permissions for ${email}`);
  } catch (error) {
    console.error('Error updating project permissions:', error);
  }
}
export { updatePermissions, getUsersForProject }
