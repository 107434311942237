import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Container,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'

const HeroSection = () => {
  const navigate = useNavigate()

  // Check the screen size to conditionally stack buttons
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box
      minHeight="80vh"
      bg="orange.100"
      color="white"
      position="relative"
      overflow="hidden"
    >
      <Container maxW="container.xl" position="relative" zIndex={2} px={{ base: 6, md: 12 }}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              minHeight="80vh"
              textAlign="center"
            >
              <Heading
                as="h1"
                size="3xl"
                mb={4}
                letterSpacing="tight"
                fontSize={{ base: '6xl', md: '8xl' }}
              >
                Google Docs
                <br />
                For Audio.
              </Heading>
              <Box
                fontSize={{ base: 'xl', md: '3xl' }}
                mb={8}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="medium"
                flexDirection={isMobile?"column":"row"}
              >
                <Box minWidth="205px" textAlign={isMobile? "center":"right"} mr={2}>
                  <TypeAnimation
                    preRenderFirstString
                    sequence={[
                      1000,
                      'Clear Feedback',
                      2000,
                      'Version Control',
                      2000,
                      'Task Management',
                      2000,
                      'Sharing Audio Files',
                      1000,
                    ]}
                    wrapper="span"
                    speed={30}
                    style={{ display: 'inline-block' }}
                    repeat={Infinity}
                    deletionSpeed={50}
                    cursor={false}
                  />
                </Box>
                <Text>has never been easier</Text>
              </Box>

              {isMobile ? (
                <VStack spacing={4} width="100%">
                  <Button
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    size="lg"
                    colorScheme="orange"
                    onClick={() => navigate('/login')}
                    fontWeight="bold"
                    width="100%"
                  >
                    Get Started For Free
                  </Button>
                  <Button
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    size="lg"
                    bg="white"
                    color="orange.500"
                    _hover={{ bg: 'orange.50' }}
                    onClick={() =>
                      (window.location.href = 'https://calendly.com/hello-soloflow')
                    }
                    fontWeight="bold"
                    width="100%"
                  >
                    Book a Demo
                  </Button>
                </VStack>
              ) : (
                <Stack direction="row" spacing={6}>
                  <Button
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    size="lg"
                    colorScheme="orange"
                    onClick={() => navigate('/login')}
                    fontWeight="bold"
                    px={8}
                  >
                    Get Started For Free
                  </Button>
                  <Button
                    as={motion.button}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    size="lg"
                    bg="white"
                    color="orange.500"
                    _hover={{ bg: 'orange.50' }}
                    onClick={() =>
                      (window.location.href = 'https://calendly.com/hello-soloflow')
                    }
                    fontWeight="bold"
                    px={8}
                  >
                    Book a Demo
                  </Button>
                </Stack>
              )}
            </Flex>
          </motion.div>
        </AnimatePresence>
      </Container>
      <Box
        position="absolute"
        bottom="-5%"
        left="50%"
        transform="translateX(-50%)"
        width="75%"
        zIndex={1}
      >
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
        </motion.div>
      </Box>
      <motion.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(45deg, #FFA500, #F4A460, #D2691E)',
          backgroundSize: '400% 400%',
          zIndex: 0,
        }}
        animate={{
          backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
        }}
        transition={{
          duration: 10,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      />
      {[...Array(5)].map((_, index) => (
        <motion.div
          key={index}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '2px',
            background: 'rgba(255, 255, 255, 0.3)',
            zIndex: 1
          }}
          animate={{
            top: ['100%', '0%'],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 5,
            ease: 'linear',
            repeat: Infinity,
            delay: index * 0.5
          }}
        />
      ))}
    </Box>
  )
}

export default HeroSection
