import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Text,
  Flex,
  Button,
  Icon,
  Image,
  HStack,
  useDisclosure,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import {
  FaFolder,
  FaPlus,
  FaBell,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaUser,
  FaSignInAlt,
  FaPlay,
} from 'react-icons/fa'
import CreateProject from '../project/CreateProject'
import { Project, Track } from '../../_helpers/types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { BASE_URL } from '../../config'
import { auth } from '../../firebase'
import axios from 'axios'
import FeedbackButton from './FeedbackButton'
import { signOut } from 'firebase/auth'
import NotificationsModal from './NotificationsModal'
import { socket } from '../../App'
import CreateTrack from '../project/CreateTrack'
import { useNotification } from './NotificationContext'

interface SidebarProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  showSidebar: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onOpen, onClose, showSidebar }) => {
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose
  } = useDisclosure()
  const {
    isOpen: isCreateTrackOpen,
    onOpen: onCreateTrackOpen,
    onClose: onCreateTrackClose
  } = useDisclosure()
  const {
    isOpen: isNotificationsOpen,
    onOpen: onNotificationsOpen,
    onClose: onNotificationsClose
  } = useDisclosure()

  const [projectsData, setProjectsData] = useState<Project[]>([])
  const [tracksData, setTracksData] = useState<Track[]>([])

  const [projectsLoading, setProjectsLoading] = useState(false)
  const [tracksLoading, setTracksLoading] = useState(false)

  const [projectsError, setProjectsError] = useState<Error | null>(null)
  const [tracksError, setTracksError] = useState<Error | null>(null)
  
  const [displayTracks, setDisplayTracks] = useState(false)
  const [currentProject, setCurrentProject] = useState<Project>()
  // const [currentProjectID, setCurrentProjectID] = useState<string>()

  const location = useLocation()

  const { unreadNotifications, unreadCount, fetchUnreadNotifications, countAndSetUnreadNotifications } = useNotification();  // Access notification count

  useEffect(() => {
    if (user) {
      // Fetch unread count initially when the component loads
      fetchUnreadNotifications(user.uid);
    }
  }, [user])

  useEffect(() => {
    countAndSetUnreadNotifications()
  }, [unreadNotifications])

  useEffect(() => {
    if (!error && user?.uid) {
      socket.emit("join_notifications", user?.uid)
      // socket.on("new_thread_notification", updateNotifications)
      setProjectsLoading(true)
      axios
        .get(`${BASE_URL}/projects?userID=${user.uid}`)
        .then((response: { data: React.SetStateAction<Project[]> }) => {
          setProjectsData(response.data)
          setProjectsLoading(false)
        })
        .catch((error) => {
          setProjectsError(error)
          setProjectsLoading(false)
        })
    }
    updateCurrentTrackAndProjectInfo()
  }, [user?.uid])

  useEffect(() => {
    updateCurrentTrackAndProjectInfo()
  }, [location])

  const updateTrackInfo = async (projectID: string) => {
    axios
      .get(`${BASE_URL}/tracks?projectID=${projectID}`)
      .then((response) => {
        const newTracks: Track[] = response.data
        setTracksData(newTracks)
      })
      .catch((error) => {
        console.error('Error fetching tracks:', error)
        setTracksError(error)
      })
  }

  const updateCurrentTrackAndProjectInfo = async () => {
    const pathParts = location.pathname.split('/').filter(Boolean)
    let projectId = ''

    if (pathParts[0] === 'projects') {
      if (pathParts.length === 3) {
        [, projectId,] = pathParts

        if (currentProject === undefined) {
          updateCurrentProject(projectId)
        }

        setDisplayTracks(true)
        setTracksLoading(true)
        updateTrackInfo(projectId)
        setTracksLoading(false)
      } else if (pathParts.length < 3) {
        [, projectId] = pathParts
        setDisplayTracks(false)
      }
    }
  }

  const updateCurrentProject = async (projectId:string) => {
    axios.get(`${BASE_URL}/projects/${projectId}`)
    .then((response) => {
      const newProject: Project = response.data
      setCurrentProject(newProject)
    })
  }

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (pathParts[0] === 'projects') {
      let projectId: string
      if (pathParts.length === 3) {
        [, projectId] = pathParts
      } else if (pathParts.length === 2) {
        [, projectId] = pathParts
      } else {
        return
      }

      // Check if the project is already in projectsData
      const projectExists = projectsData.some((project) => project.id === projectId)

      if (!projectExists) {
        setProjectsLoading(true)
        axios
          .get(`${BASE_URL}/projects/${projectId}`)
          .then((response) => {
            const newProject: Project = response.data

            setProjectsData((prevData) => {
              // Check again if the project already exists to prevent race conditions
              if (prevData.some((project) => project.id === newProject.id)) {
                return prevData
              }
              return [...prevData, newProject]
            })
            setProjectsLoading(false)
          })
          .catch((error) => {
            console.error('Error fetching project:', error)
            setProjectsLoading(false)
          })
      }
    }
  }, [])

  const isValidProject = !loading && !projectsLoading && !projectsError && projectsData
  const isValidTrack = !loading && !tracksLoading && !tracksError && tracksData

  useEffect(() => {
    onOpen()
  }, [onOpen])

  const handleSignOut = async () => {
    try {
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const handleSignIn = () => {
    navigate(`/login?returnUrl=${window.location.pathname}`)
  }

  if (!showSidebar) {
    return null
  }

  return (
    <>
      <Button
        position='fixed'
        top='5%'
        left={isOpen ? '300px' : '0'}
        transform='translateY(-50%)'
        zIndex='999'
        onClick={isOpen ? onClose : onOpen}
        colorScheme='orange'
        size='xs'
        borderRadius='0 4px 4px 0'
        transition='left 0.3s'
        height='30px'
      >
        <Icon as={isOpen ? FaChevronLeft : FaChevronRight} width='auto' />
      </Button>
      <Box
        position='fixed'
        left='0'
        top='0'
        bottom='0'
        width={isOpen ? '300px' : '0'}
        overflowY='auto'
        bg='orange.100'
        color='black'
        transition='width 0.3s'
        zIndex='998'
        display='flex'
        flexDirection='column'
      >
        <Box p={4} bg='orange.100'>
          <Flex justify='space-between' align='center'>
            <Button onClick={() => navigate('/')} variant= 'link'>
              <Image src='/logo.png' w='150px' />
            </Button>            
            <HStack alignItems='center'>
              <IconButton
                aria-label="Notifications"
                icon={<>
                  <Icon as={FaBell} w={5} h={5} color='orange.600' />
                  {unreadCount > 0 && (
                    <Box
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      bg="red.500"
                      borderRadius="full"
                      width="18px"
                      height="18px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="xs"
                      fontWeight="bold"
                      color="white"
                    >
                      {unreadCount}
                    </Box>
                  )}
                </>}
                onClick={onNotificationsOpen}
                variant="ghost"
              />
              <Menu>
                <MenuButton>
                  {user && user.photoURL ? (
                    <Image
                      src={user.photoURL}
                      width='32px'
                      height='32px'
                      borderRadius='full'
                    />
                  ) : (
                    <Icon as={FaUser} w={5} h={5} mr={2} color='orange.600' />
                  )}
                </MenuButton>
                {user ? (
                  <MenuList>
                    <HStack my={1}>
                      <Icon as={FaUser} w={4} h={4} ml={3} mr='auto' color='orange.600' />
                      <Text fontSize='md' align='center' mr='auto'>
                        {user.email}
                      </Text>
                    </HStack>
                    <MenuItem icon={<FaSignOutAlt color='red' />} onClick={handleSignOut}>
                      Sign Out
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <MenuItem icon={<FaSignInAlt color='green' />} onClick={handleSignIn}>
                      Sign In
                    </MenuItem>
                  </MenuList>
                )}
              </Menu>
            </HStack>
          </Flex>
        </Box>

        {projectsError && (
          <Box p={4} color='red.500'>
            <Text>Error loading projects: {projectsError.message}</Text>
          </Box>
        )}

        <VStack align='stretch' spacing={4} p={4}>
          {user ? (
            <Button
            variant='ghost'
            justifyContent='flex-start'
            leftIcon={<Icon as={FaFolder} />}
            color='black'
            onClick={() => navigate('/projects')}
          >
            Library
          </Button>
          ): (
            <></>
          )}
          
            {displayTracks ? (
              <Text fontWeight='bold'>Tracks</Text>
            ): (
              <Text fontWeight='bold'>Projects</Text>
            )}

          {displayTracks ? (isValidTrack ? (
            tracksData?.sort((a:Track, b:Track) => {
              const dateA = new Date(a.createdAt)
              const dateB = new Date(b.createdAt)
              return dateA.getTime() - dateB.getTime()
            })
            .reverse()
            .map((track:Track) => (
              <Button
                  key={track.id}
                  bg='orange.100'
                  color='black'
                  justifyContent='flex-start'
                  leftIcon={<Icon as={FaPlay} />}
                  _hover={{ bg: 'orange.200' }}
                  onClick={() => navigate(`/projects/${track.projectID}/${track.id}`)}
                >
                  <Text whiteSpace='nowrap' textOverflow='ellipsis' overflowX='hidden'>
                    {track.name}
                  </Text>
                </Button>
            ))
          ) : (
            <Flex justify='center'>
              <Spinner />
            </Flex>
          )) : isValidProject ? (
            projectsData
              ?.sort((a: Project, b: Project) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateA.getTime() - dateB.getTime()
              })
              .reverse()
              .map((project: Project) => (
                <Button
                  key={project.id}
                  bg='orange.100'
                  color='black'
                  justifyContent='flex-start'
                  leftIcon={<Icon as={FaFolder} />}
                  _hover={{ bg: 'orange.200' }}
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <Text whiteSpace='nowrap' textOverflow='ellipsis' overflowX='hidden'>
                    {project.name}
                  </Text>
                </Button>
              ))
          ) : (
            <Flex justify='center'>
              <Spinner />
            </Flex>
          )}

          <Tooltip label={user ? '' : 'Sign in to access this'} isDisabled={user?true:false}>
          <Button
            variant='ghost'
            justifyContent='flex-start'
            leftIcon={<Icon as={FaPlus} />}
            onClick={displayTracks ? onCreateTrackOpen : onCreateProjectOpen}
            color='black'
            isDisabled={!user}
          >
            {(displayTracks ? "New Track" : "New Project")}
          </Button>
          </Tooltip>

        </VStack>

        <Box width={'fit-content'} px={4} mt='auto'>
          <FeedbackButton />
        </Box>
      </Box>
      <CreateProject isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} />
      { currentProject && 
      <CreateTrack isOpen={isCreateTrackOpen} onClose={onCreateTrackClose} project={currentProject}/>
      }
      <NotificationsModal
        isOpen={isNotificationsOpen}
        onClose={onNotificationsClose}
      />
    </>
  )
}

export default Sidebar